import React from 'react';
import WidgetError from '../components/WidgetError';
import '../locales/i18n';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <WidgetError message={t("We are unable to find a widget at this URL.")} />
    )
}

export default NotFound;