import { createContext } from "react";
import { ContainerPostEventType } from '@statflo/widget-sdk/dist/shared';
import { WidgetClient } from "@statflo/widget-sdk";

export interface TUserContext {
    client: WidgetClient;
    token: string | null;
    dealerId: string | null;
    carrierId: string | null;
    userId: string | null;
    userLanguage: string;
    size: string;
    expanded: boolean;
    maxHeight: string | number | null;
    setExpanded?: (val: boolean) => void;
    event: ContainerPostEventType | null;
    setDefaultScroll: (val: boolean) => void;
}

export const UserContext = createContext<TUserContext>(null!);