import { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import useAuthorization from './hooks/useAuthorization'
import { GlobalStyle } from './theme'
import './widget'
import Root from './Root';
import './App.scss';
import DevToken from './components/DevToken';
import useScript from './hooks/useScript';
import Loader from './components/Loader';
import { defaultTheme as theme } from './theme'
import WidgetError from './components/WidgetError';
import { useTranslation } from "react-i18next";
import './locales/i18n';
import { WidgetClient } from '@statflo/widget-sdk'
import { TWidgetState } from '@statflo/widget-sdk/dist/shared'

export const createWidgetState = (id: string): TWidgetState => {
    return ({
        id,
        type: null,
        containerDomain: "*",
        footer: "",
        header: "",
        isOpen: false,
        isReady: false,
        isShown: false,
        label: "",
        maxHeight: null,
        size: null,
        url: "",
        widgetDomain: "*",
    });
};

export enum WidgetState {
    containerDomain = "containerDomain",
    footer = "footer",
    header = "header",
    id = "id",
    isOpen = "isOpen",
    isReady = "isReady",
    isShown = "isShown",
    label = "label",
    maxHeight = "maxHeight",
    size = "size",
    type = "type",
    url = "url",
    widgetDomain = "widgetDomain",
}

export enum WidgetViewSize {
    Large = "Large",
    Medium = "Medium",
    Small = "Small",
}

const ContainerEvents = {
    activeConversationChanged: "active_conversation_changed",
};

const WidgetEvents = {
    postForwardExample: "post_forward_example",
    actionCreationFailed: "action_creation_failed",
    appendTextToMessage: "append_text_to_message",
    replaceTextMessage: "replace_text_message",
};

export const EventNames = {
    widget: WidgetEvents,
    container: ContainerEvents,
};

function App() {
    useScript("https://cdn.statflo.com/scripts/iframeResizer.contentWindow.min.js");
    const widget = useAuthorization();
    const [ready, setReady] = useState<boolean>(false);
    const [dev, setDev] = useState<boolean>(false);
    const [client, setClient] = useState<WidgetClient>();
    const { t } = useTranslation();

    useEffect(() => {
        const checkDevToken = async () => {
            let token: string | null = await sessionStorage.getItem('token');

            if (!token && process.env.NODE_ENV === 'development') {
                setDev(true);
            }
        }

        checkDevToken();
    }, []);

    useEffect(() => {
        if (window) {
            const { name } = window;
            const id = (name && name.length > 0) ? name : 'local';
            const widgetClient = new WidgetClient({ window, id, createWidgetState });
            setClient(widgetClient);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window]);

    useEffect(() => {
        if (widget.authorized && !widget.loading) {
            setReady(true);
        }
    }, [widget]);

    if (process.env.NODE_ENV === 'development' && dev) {
        return (
            <DevToken />
        );
    }

    const displayWidget = () => {
        if (!ready && !widget.loading && !widget.authorized) {
            return <WidgetError 
                message={t('We are unable to load this widget.')}
                onRefresh={() => window.location.reload()}
            />;
        }

        if (widget.authorized && widget.loading && !ready) {
            return <Loader />;
        }

        if (client && ready && !widget.loading && widget.authorized) {
            return <Root
                client={client}
                carrierId={widget.carrierId}
                dealerId={widget.dealerId}
                userId={widget.userId}
                token={widget.token}
                userLanguage={widget.userLanguage}
            />;
        }

        return null;
    }

    return (
        <>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                {displayWidget()}
            </ThemeProvider>
        </>
    )
}

export default App
