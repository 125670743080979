import { useEffect, useState } from 'react'
import { UserContext } from './context/UserContext'
import './widget'
import Routes from './router/Routes'
import { ContainerMethods, ContainerPostEventType } from '@statflo/widget-sdk/dist/shared';
import { WidgetViewSize } from './App';
import { WidgetClient } from '@statflo/widget-sdk';

interface RootProps {
    client: WidgetClient;
    carrierId: string | null;
    dealerId: string | null;
    token: string | null;
    userId: string | null;
    userLanguage: string;
}

interface WidgetGlobalState {
    size: WidgetViewSize;
    isOpen: boolean;
    maxHeight: string | number | null;
    defaultScroll: boolean;
}

function Root({ client, carrierId, dealerId, token, userId, userLanguage }: RootProps) {
    const [state, setState] = useState<WidgetGlobalState>({
        size: WidgetViewSize.Medium,
        isOpen: false,
        maxHeight: null,
        defaultScroll: true,
    });
    const [eventMessage, setEventMessage] = useState<ContainerPostEventType | null>(null);

    // Handle specific incoming events such as maximize/minimize/resize
    useEffect(() => {
        client.on(ContainerMethods.setState, (e) => {
            const { property, value } = e.payload;
            setState(currState => ({...currState, ...{[property]: value }}));
            setEventMessage(e);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <UserContext.Provider
            value={{
                client,
                token,
                dealerId,
                carrierId,
                userId,
                size: state.size,
                expanded: (state.isOpen || (state.size === WidgetViewSize.Large)) ? true : false,
                setExpanded: (isOpen: boolean) => setState(currState => ({ ...currState, isOpen })),
                event: eventMessage,
                maxHeight: state.maxHeight,
                setDefaultScroll: (defaultScroll: boolean) => setState(currState => ({ ...currState, defaultScroll })),
                userLanguage
            }}
        >
            <div style={{
                display: 'flex',
                width: '100%',
                minHeight: 'auto',
                height: 'auto',
                maxHeight: state.maxHeight ?? 'none',
                overflowX: 'hidden',
                overflowY: state.defaultScroll ? (state.maxHeight ? 'scroll' : 'hidden') : 'hidden',
            }}>
                <Routes />
            </div>
        </UserContext.Provider>
    )
}

export default Root
